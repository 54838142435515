import { useUserCompany } from "@/contexts/user/hook";
import { Typography, Chip, Skeleton } from "@mui/material";

export const AccountInfo = () => {
  const { companyAttributes } = useUserCompany();
  const email = companyAttributes?.email;
  const hasSubscriptionActive =
    companyAttributes?.["custom:stripeSubscription"] === "ACTIVE";

  return (
    <div className="flex flex-col gap-y-2 px-4">
      <div className="flex items-center gap-x-2">
        <Typography variant="h6">Sua conta</Typography>
        {hasSubscriptionActive ? (
          <Chip label="ULTRA" color="secondary" size="small" />
        ) : null}
      </div>

      <Typography variant="body2" className="text-gray-500">
        {email ?? <Skeleton />}
      </Typography>
    </div>
  );
};
