import { ResponseData as InvitedBarbersResponseData } from "@/shared/hooks/establishment/use-list-invited-barbers";

export const getSelectedBarber = (
  { invitedBarbers, ownerBarber }: InvitedBarbersResponseData,
  barberId: string
) => {
  if (ownerBarber?.companyId === barberId) {
    return {
      companyId: ownerBarber.companyId,
      givenName: ownerBarber.givenName,
      familyName: ownerBarber.familyName,
      email: ownerBarber.email,
    };
  }

  const selectedBarber = invitedBarbers?.find(
    ({ linkedCompanyId }) => linkedCompanyId === barberId
  );

  if (!selectedBarber) {
    return null;
  }

  return {
    companyId: selectedBarber.linkedCompanyId,
    givenName: selectedBarber.linkedBarber.givenName,
    familyName: selectedBarber.linkedBarber.familyName,
    email: selectedBarber.linkedEmail,
  };
};
