import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import { AppointmentInput, useAppointmentFormSchema } from "./use-form-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Suspense } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { DataClientForm } from "./data-client-form";
import { useListInvitedBarbers } from "@/shared/hooks/establishment/use-list-invited-barbers";
import { useAttending } from "@/contexts/attending/hook";
import { useInvalidateListAppointments } from "@/shared/hooks/establishment/appointments/use-list-appointments";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { getBookingWeekDays } from "@/shared/helpers/working-hour";
import { Establishment } from "@/shared/types/establishments";
import { useCreateAppointment } from "@/shared/hooks/establishment/appointments/use-create-appointment";
import { getSelectedBarber } from "./helper";
import { AxiosError } from "axios";
import { addDays, format } from "date-fns";

type Props = {
  establishment: Establishment;
  onFinish(): void;
};

const NO_PERSON_NAMES_DETECTED = "NO_PERSON_NAMES_DETECTED";

export const AddClientBooking = ({ establishment, onFinish }: Props) => {
  const { establishmentId, companyId } = establishment;
  const enabledWeekDays = getBookingWeekDays(establishment);

  const { selectedDate } = useAttending();
  const schema = useAppointmentFormSchema();
  const formMethods = useForm<AppointmentInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      serviceTypes: "",
      clientName: "",
      appointmentAt: selectedDate,
    },
  });
  const { handleSubmit, setError, control } = formMethods;

  const invalidateListAppointments = useInvalidateListAppointments();
  const {
    data: { data: listInvitedBarbers },
  } = useListInvitedBarbers({ companyId, establishmentId });
  const { mutate: createAppointment, isPending } = useCreateAppointment();
  const { showSnackbar } = useGlobalSnackbar();

  async function submit({
    clientName,
    appointmentAt,
    ...props
  }: AppointmentInput) {
    appointmentAt.setSeconds(0);
    appointmentAt.setMilliseconds(0);

    const { serviceTypes } =
      "serviceTypes" in props ? props : { serviceTypes: "" };
    const { barberId } = "barberId" in props ? props : { barberId: null };
    const barber = getSelectedBarber(listInvitedBarbers, barberId ?? "");

    createAppointment(
      {
        clientName,
        serviceTypes,
        establishmentId,
        appointmentAt: appointmentAt.toISOString(),
        barber,
      },
      {
        onSettled() {
          invalidateListAppointments({
            establishmentId,
            startDate: format(appointmentAt, "yyyy-MM-dd'T03:00:00'"),
            endDate: format(addDays(appointmentAt, 1), "yyyy-MM-dd'T02:59:59'"),
          });
        },
        onSuccess() {
          showSnackbar({
            message: "Horário agendado com sucesso.",
            severity: "success",
          });

          onFinish();
        },
        onError(error) {
          showSnackbar({
            message:
              "Erro ao agendar horário com a barbearia, tente novamente.",
            severity: "error",
          });
          if (error instanceof AxiosError) {
            if (error.response?.data.message === NO_PERSON_NAMES_DETECTED) {
              setError("clientName", {
                message: "Nenhum nome detectado, digite um nome real.",
              });
            }
            if (error.response?.data.code === "APPOINTMENT_OVERLAPPING") {
              setError("appointmentAt", {
                message: "Horário já reservado, escolha outro horário.",
              });
              showSnackbar({
                message: "Horário já reservado, escolha outro horário.",
                severity: "warning",
                timeout: 3000,
              });
            }
          }
        },
      }
    );
  }

  return (
    <FormProvider {...formMethods}>
      <Suspense fallback={<CircularProgress className="mx-auto" />}>
        <form
          onSubmit={handleSubmit(submit)}
          className="flex flex-col gap-y-8 w-full"
        >
          <div className="flex flex-col gap-y-4 w-full">
            <Controller
              control={control}
              name="appointmentAt"
              defaultValue={selectedDate}
              render={({ field }) => (
                <MobileDateTimePicker
                  label="Data e hora do agendamento"
                  value={field.value}
                  defaultValue={selectedDate}
                  shouldDisableDate={(date) =>
                    !enabledWeekDays.includes(date.getDay())
                  }
                  onAccept={(value) => {
                    if (value) {
                      field.onChange(value);
                    }
                  }}
                />
              )}
            />

            <DataClientForm
              companyId={companyId}
              establishmentId={establishmentId}
            />
          </div>

          <div className="self-end">
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isPending}
            >
              Reservar horário
            </LoadingButton>
          </div>
        </form>
      </Suspense>
    </FormProvider>
  );
};

export default AddClientBooking;
