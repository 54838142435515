import { FC, useEffect } from "react";
import { Drawer, IconButton, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CloseOutlined } from "@mui/icons-material";
import { useInvalidateListServiceTypes } from "../../hooks/establishment/service-types/use-list-service-types";
import { useCurrentUser } from "../../hooks/auth/use-user-attributes";
import { useEditServiceType } from "@/shared/hooks/establishment/service-types/use-edit-service-type";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { ServiceTypeForm } from "./service-type-form";
import { ServiceTypeInputs } from "./types";
import { serviceTypeSchema } from "./schema";
import { ListServiceTypeOutput } from "@/shared/services/barx-api/establishments/service-types/list";
import { format } from "date-fns";

type Props = {
  open: boolean;
  onClose(): void;
  establishmentId: string;
  serviceType?: ListServiceTypeOutput["serviceTypes"][number];
};

const DEFAULT_DURATION = new Date(2024, 11, 17, 0, 40, 0, 0);

export const EditServiceTypeDrawer: FC<Props> = ({
  onClose,
  open,
  establishmentId,
  serviceType,
}) => {
  const { data: user } = useCurrentUser();
  const companyId = user.userId;
  const methods = useForm<ServiceTypeInputs>({
    resolver: yupResolver(serviceTypeSchema),
  });
  const { handleSubmit, reset, setValue } = methods;
  const { mutate: editServiceType, isPending } = useEditServiceType();
  const invalidateListServiceTypesQuery = useInvalidateListServiceTypes();
  const { showSnackbar } = useGlobalSnackbar();

  function handleClose() {
    reset({
      amount: null,
      name: "",
      consumedItems: [],
      duration: DEFAULT_DURATION,
    });
    onClose();
  }

  async function submit({
    name,
    amount,
    consumedItems,
    duration,
  }: ServiceTypeInputs) {
    if (!serviceType) throw new Error("Service type is required");
    const durationFormatted = format(duration, "HH:mm");

    editServiceType(
      {
        establishmentId,
        name,
        amount: amount!,
        serviceTypeId: serviceType.serviceTypeId,
        consumedItems,
        duration: durationFormatted,
      },
      {
        onSuccess() {
          showSnackbar({
            message: "Serviço atualizado com sucesso.",
            severity: "success",
          });
          invalidateListServiceTypesQuery({
            companyId,
            establishmentId,
          });
          handleClose();
        },
        onError() {
          showSnackbar({
            message: "Erro ao atualizar serviço, tente novamente.",
            severity: "error",
          });
        },
      }
    );
  }

  useEffect(() => {
    if (!serviceType) return;

    setValue("name", serviceType.name);
    setValue("amount", serviceType.amount);
    setValue("consumedItems", serviceType.consumedItems);
    setValue("duration", new Date(`2024-11-17T${serviceType.duration}`));
  }, [serviceType, setValue]);

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <div className="p-4 flex flex-col gap-y-6 w-screen max-w-[300px] sm:max-w-md">
        <header className="flex justify-between items-center">
          <Typography variant="h5" fontWeight="bold">
            Alterar serviço
          </Typography>
          <IconButton onClick={handleClose} color="error">
            <CloseOutlined />
          </IconButton>
        </header>

        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(submit)}
            className="flex flex-col gap-y-4"
          >
            <ServiceTypeForm />

            <LoadingButton
              variant="contained"
              type="submit"
              loading={isPending}
            >
              Alterar
            </LoadingButton>
          </form>
        </FormProvider>
      </div>
    </Drawer>
  );
};

export default EditServiceTypeDrawer;
