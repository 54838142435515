import { apiAdmin } from "@/shared/services/api";
import { AppointmentStatus } from "@/shared/types/appointments";
import {
  useQueryClient,
  useSuspenseInfiniteQuery,
} from "@tanstack/react-query";

type Props = {
  establishmentId: string;
  linkedEmail?: string;
  startDate?: string;
  endDate?: string;
  status?: AppointmentStatus;
  limit?: number;
  lastEvaluatedKey?: string;
};

export type ListAppointmentsResponseData = {
  appointments: [
    {
      companyId: string;
      establishmentId: string;
      appointmentId: string;
      customerId: string;
      clientName: string;
      serviceTypes: {
        name: string;
        amount: number;
        serviceTypeId: string;
      }[];
      appointmentAt: string;
      duration: string;
      createdAt: string;
      status: "REJECTED" | "PENDING" | "ACCEPTED" | "CONCLUDED";
      barber: null | {
        givenName: string;
        familyName: string;
        companyId: string;
      };
    }
  ];
  paging: {
    count: number;
    scannedCount: number;
    lastEvaluatedKey?: any;
  };
};

const DEFAULT_LIMIT = 20;

export const useListAppointments = ({
  establishmentId,
  linkedEmail,
  endDate,
  startDate,
  status,
  limit = DEFAULT_LIMIT,
  lastEvaluatedKey,
}: Props) =>
  useSuspenseInfiniteQuery({
    queryKey: mountQueryKey({
      establishmentId,
      linkedEmail,
      startDate,
      endDate,
      status,
      limit,
      lastEvaluatedKey,
    }),
    queryFn: async ({ pageParam }) => {
      const http = await apiAdmin();
      return http.get<ListAppointmentsResponseData>(
        `/establishments/${establishmentId}/appointments`,
        {
          params: { startDate, endDate, status, limit, linkedEmail },
          headers: {
            lastEvaluatedKey: pageParam,
          },
        }
      );
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => {
      const lastAppointment = lastPage.data.paging.lastEvaluatedKey;
      if (!lastAppointment) return null;
      return JSON.stringify(lastAppointment) as any;
    },
  });

const mountQueryKey = ({
  establishmentId,
  linkedEmail,
  endDate,
  startDate,
  lastEvaluatedKey,
  limit = DEFAULT_LIMIT,
  status,
}: Props) => [
  "listAppointments",
  establishmentId,
  linkedEmail,
  startDate,
  endDate,
  status,
  limit,
  lastEvaluatedKey,
];

export const useInvalidateListAppointments = () => {
  const queryClient = useQueryClient();

  return ({
    establishmentId,
    linkedEmail,
    endDate,
    startDate,
    lastEvaluatedKey,
    limit,
    status,
  }: Props) =>
    queryClient.invalidateQueries({
      queryKey: mountQueryKey({
        establishmentId,
        linkedEmail,
        endDate,
        startDate,
        lastEvaluatedKey,
        limit,
        status,
      }),
    });
};
