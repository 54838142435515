"use client";

import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Inputs } from "./use-form-schema";
import { useFormContext } from "react-hook-form";
import { useListServiceTypes } from "@/shared/hooks/establishment/service-types/use-list-service-types";
import { useListInvitedBarbers } from "@/shared/hooks/establishment/use-list-invited-barbers";
import { toCurrency } from "@/shared/utils";

type OnlyClientNameInput = { clientName: Inputs["clientName"] };

type Props = {
  companyId: string;
  establishmentId: string;
};

export const DataClientForm = ({ companyId, establishmentId }: Props) => {
  const { formState, register, watch } = useFormContext<
    Inputs | OnlyClientNameInput
  >();

  const {
    data: {
      data: { serviceTypes },
    },
  } = useListServiceTypes({
    companyId,
    establishmentId,
  });
  const {
    data: {
      data: { invitedBarbers, ownerBarber },
    },
  } = useListInvitedBarbers({ companyId, establishmentId });

  const serviceTypesWatch = watch("serviceTypes");
  const barberWatch = watch("barberId");

  return (
    <div className="flex flex-col gap-y-4 w-full">
      <TextField
        {...register("clientName")}
        label="Nome"
        autoComplete="given-name"
        placeholder="Digite seu nome"
        error={!!formState.errors.clientName}
        helperText={formState.errors.clientName?.message}
      />

      <FormControl>
        <InputLabel id="serviceTypes">Escolher serviço</InputLabel>

        <Select
          {...register("serviceTypes")}
          value={serviceTypesWatch}
          labelId="serviceTypes"
          input={<OutlinedInput label="Escolher serviço" />}
          autoComplete="off"
        >
          {!serviceTypes.length ? (
            <MenuItem disabled>Nenhum serviço disponível</MenuItem>
          ) : null}

          {serviceTypes.map((serviceTypes) => (
            <MenuItem
              key={serviceTypes.serviceTypeId}
              value={serviceTypes.serviceTypeId}
            >
              {serviceTypes.name} {toCurrency(serviceTypes.amount)}
            </MenuItem>
          ))}
        </Select>

        {"serviceTypes" in formState.errors ? (
          <FormHelperText error={!!formState.errors.serviceTypes}>
            {formState.errors.serviceTypes?.message}
          </FormHelperText>
        ) : null}
      </FormControl>

      {invitedBarbers.length ? (
        <FormControl>
          <InputLabel id="barber">Escolher barbeiro</InputLabel>

          <Select
            {...register("barberId")}
            value={barberWatch}
            labelId="barber"
            input={<OutlinedInput label="Escolher barbeiro" />}
            autoComplete="off"
          >
            {!invitedBarbers.length ? (
              <MenuItem disabled>Nenhum barbeiro disponível</MenuItem>
            ) : null}

            <MenuItem value={ownerBarber.companyId}>
              {ownerBarber.givenName} {ownerBarber.familyName}
            </MenuItem>

            {invitedBarbers.map((barber) => (
              <MenuItem
                key={barber.linkedCompanyId}
                value={barber.linkedCompanyId}
              >
                {barber.linkedBarber.givenName} {barber.linkedBarber.familyName}
              </MenuItem>
            ))}
          </Select>

          {"barberId" in formState.errors ? (
            <FormHelperText error={!!formState.errors.barberId}>
              {formState.errors.barberId?.message}
            </FormHelperText>
          ) : (
            <FormHelperText>
              Caso você não escolher um barbeiro, o próximo barbeiro disponível
              irá te atender.
            </FormHelperText>
          )}
        </FormControl>
      ) : null}
    </div>
  );
};
