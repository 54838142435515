import { TextLabel } from "@/shared/components/text-label";
import { GetResultsOutput } from "@/shared/hooks/establishment/use-get-results";
import { Typography, Skeleton, Divider } from "@mui/material";
import { useIsFetching } from "@tanstack/react-query";
import { useMemo } from "react";

type Props = {
  consumedItems?: GetResultsOutput["consumedItems"];
  servicesDetail?: GetResultsOutput["services"]["details"];
};

export const ConsumableProductsInfo = ({
  consumedItems,
  servicesDetail,
}: Props) => {
  const totalItems = Object.values(consumedItems?.total ?? {});
  const detailedConsumedItems = useMemo(
    () =>
      Object.keys(consumedItems?.details ?? {})
        .map((key) => {
          if (!consumedItems?.details[key]) return;
          return {
            serviceName: servicesDetail?.[key].name,
            items: Object.values(consumedItems.details[key]),
          };
        })
        .filter(Boolean),
    [consumedItems?.details, servicesDetail]
  );
  const isFetching = useIsFetching() > 0;

  return (
    <>
      <div>
        <Typography color="textSecondary" variant="subtitle1" fontWeight="bold">
          Consumo total
        </Typography>
        <Divider />

        {totalItems.length ? (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {totalItems.map((item) => (
              <div key={item.name}>
                <TextLabel
                  label={item.name}
                  text={`${item.quantity} (${item.unity})`}
                />
              </div>
            ))}
          </div>
        ) : (
          <Typography className="col-span-full" color="textSecondary">
            Nenhum registro de consumo até o momento.
          </Typography>
        )}
      </div>

      {!consumedItems?.details.length && isFetching ? (
        <div className="flex flex-col gap-4">
          <Skeleton variant="rounded" width="100%" />
          <Skeleton variant="rounded" width="100%" />{" "}
        </div>
      ) : (
        <div className="mt-6">
          <Typography
            color="textSecondary"
            variant="subtitle1"
            fontWeight="bold"
          >
            Consumo detalhado
          </Typography>
          <Divider />

          <div className="grid max-sm:grid-cols-2 sm:grid-cols-3 gap-6 mt-2">
            {(!totalItems.length || !detailedConsumedItems.length) &&
            !isFetching ? (
              <Typography className="col-span-full" color="textSecondary">
                Nenhum registro de consumo até o momento.
              </Typography>
            ) : (
              detailedConsumedItems.map((items) => (
                <div key={items?.serviceName}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {items?.serviceName}
                  </Typography>

                  <Divider />

                  <div className="grid grid-cols-2 gap-4">
                    {items?.items.map((item) => (
                      <div key={item.name}>
                        {item.name ? (
                          <TextLabel
                            label={item.name}
                            text={`${item.quantity} (${item.unity})`}
                          />
                        ) : (
                          <Typography
                            className="col-span-full"
                            color="textSecondary"
                          >
                            Nenhum registro de consumo até o momento.
                          </Typography>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </>
  );
};
