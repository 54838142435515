import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { FirebaseApp, initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "@firebase/messaging";
import { FIREBASE_CONFIG } from "@/shared/constants/env";
import { useEffect, useState } from "react";
import { captureException } from "@sentry/react";
import { fetchAuthSession } from "aws-amplify/auth";
import { useLocation } from "react-router-dom";

const firebaseConfig = JSON.parse(FIREBASE_CONFIG || "{}");

export const useInitializeFirebase = () => {
  const [app, setApp] = useState<FirebaseApp>();
  const { pathname } = useLocation();

  useEffect(() => {
    if (app) return;

    setApp(() => initializeApp(firebaseConfig));
  }, [app]);

  useEffect(() => {
    if (!app) return;

    const analytics = getAnalytics(app);

    logEvent(analytics, "page_view", {
      page_path: pathname,
      page_title: document.title,
    });
  }, [app, pathname]);

  useEffect(() => {
    if (!app) return;

    fetchAuthSession()
      .then(({ userSub }) => {
        if (!userSub) return;
        const analytics = getAnalytics(app);
        setUserId(analytics, userSub);
      })
      .catch((err) => {
        console.error(
          "Failed to get the userId to be used on firebase analytics: ",
          err
        );
      });
  }, [app]);

  useEffect(() => {
    if (!app) return;

    try {
      const messaging = getMessaging(app);
      const unsubscribe = onMessage(messaging, (payload) => {
        console.log("Message received. ", payload);
      });

      return unsubscribe;
    } catch (error) {
      captureException(error);
    }
  }, [app]);

  useEffect(() => {
    if (!app) return;

    const analytics = getAnalytics(app);

    logEvent(analytics, "page_view", {
      page_path: pathname,
      page_title: document.title,
    });
  }, [app, pathname]);
};
